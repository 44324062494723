.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.parent {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
}

.nota-link {
    display: flex;
    flex-direction: column;
}

.grid-item {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light-gray);
}

.note-author {
    color: var(--gray-text);
    font-family: "Roboto", sans-serif;
}

.grid-item img {
    width: 100%;
}

.grid-item h3 {
    font-size: 1.25rem;
    font-family: "Roboto", sans-serif;
    padding: 0.75rem 0;
    font-weight:400;
    margin: 0;
    line-height: 1.3;
}

.grid-item .highlighted-text {
    color: var(--sky-blue)
}

.sponsored{
    position: relative;
}

.sponsored p{
    display: flex;
    align-items: center;
    position: absolute;
    -moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: 1rem;
    background: var(--sponsored);
    opacity: .9;
    color: var(--white);
    padding: .5rem .9rem .35rem .9rem;
    border-radius: 4px;
    font-size: .67rem;
    text-transform: uppercase;
    letter-spacing: 0px;
}

@media (max-width: 1024px) {
    .parent {
        column-gap: 1.2rem;
    }
}

@media (max-width: 650px) {
    .grid-item h3 {
        font-size: 1.1rem;
        font-family: "Roboto", sans-serif;
        padding-bottom: 0.75rem;
    }
}